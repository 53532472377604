import { createRouter, createWebHistory, RouteRecordRaw, RouterOptions } from 'vue-router'
import { onInitialApiRequestsCompleted, Priority } from '@/utils/helpers/hooks'
import { componentInProjectGuardMiddleware } from './guards/componentInProjectGuard'
import { homepageGuardMiddleware } from './guards/homepageGuard'
import { roleGuardMiddleware } from './guards/roleGuard'
import { ProjectLinkData } from '@/vuex/projects/types'
import { projectGuardMiddleware } from './guards/projectGuard'
import { queryGuardMiddleware } from './guards/QueryGuard/queryGuard'
import store from '@/vuex'
import texts from '@theme/texts'
import { titleGuardMiddleware } from './guards/titleGuard'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import AssetOverview from '@/views/AssetOverview/index.vue'
import Portfolio from '@/views/Portfolio/index.vue'
import { lazyLoadComponent } from '@/utils/helpers/lazyLoadComponent'
const Administration = lazyLoadComponent(() => import('@/views/Administration/index.vue'))
const AdministrationUserDetails = lazyLoadComponent(() => import('@/views/Administration/UserDetails/index.vue'))
const AdministrationUsers = lazyLoadComponent(() => import('@/views/Administration/Users/index.vue'))
const AIControls = lazyLoadComponent(() => import('@/views/AIControls/index.vue'))
const AIControlsApp = lazyLoadComponent(() => import('@/views/AIControls/App/index.vue'))
const AIControlsLog = lazyLoadComponent(() => import('@/views/AIControls/Log/index.vue'))
const AssetProfile = lazyLoadComponent(() => import('@/views/AssetProfile/index.vue'))
const AssetDocuments = lazyLoadComponent(() => import('@/views/AssetDocuments/index.vue'))
const AuthCallback = lazyLoadComponent(() => import('@/views/Authentication/AuthCallback.vue'))
const AuthFailed = lazyLoadComponent(() => import('@/views/Authentication/AuthFailed.vue'))
const AuthSilentRenew = lazyLoadComponent(() => import('@/views/Authentication/AuthSilentRenew.vue'))
const CookieList = lazyLoadComponent(() => import('@/views/App/CookieList.vue'))
const DataPointsView = lazyLoadComponent(() => import('@/views/DataPointsView/index.vue'))
const DataPrivacy = lazyLoadComponent(() => import('@/views/App/DataPrivacy.vue'))
const EnergyAndCO2 = lazyLoadComponent(() => import('@/views/EnergyAndCO2/index.vue'))
const Forbidden = lazyLoadComponent(() => import('@/views/Forbidden.vue'))
const IFrame = lazyLoadComponent(() => import('@/views/IFrame.vue'))
const Meter = lazyLoadComponent(() => import('@/views/Meter/index.vue'))
const OptimizationChecklist = lazyLoadComponent(() => import('@/views/Optimization/Checklist/index.vue'))
const OptimizationComponents = lazyLoadComponent(() => import('@/views/Optimization/Components/index.vue'))
const OptimizationOptimizations = lazyLoadComponent(() => import('@/views/Optimization/Optimizations/index.vue'))
// TODO unskip SavingsPotentials E2E tests upon uncommenting this
// const OptimizationSavings = lazyLoadComponent(() => import('@/views/Optimization/Savings/index.vue'))
const OptimizationRecommendations = lazyLoadComponent(() => import('@/views/Optimization/Recommendations/index.vue'))
const OptimizationSelectedComponentAnalyses = lazyLoadComponent(() => import('@/views/Optimization/Components/SelectedComponentAnalyses/index.vue'))
const PageNotFound = lazyLoadComponent(() => import('@/views/PageNotFound.vue'))
const Reporting = lazyLoadComponent(() => import('@/views/Reporting/index.vue'))
const StatusAndAlerts = lazyLoadComponent(() => import('@/views/StatusAndAlerts/index.vue'))
const Status = lazyLoadComponent(() => import('@/views/Status/index.vue'))
// const Savings = lazyLoadComponent(() => import('@/views/Savings/index.vue'))
const UserProfile = lazyLoadComponent(() => import('@/views/User/Profile/index.vue'))
const UserSecurity = lazyLoadComponent(() => import('@/views/User/Security/index.vue'))

/**
 * Every route requires at least:
 * - component: The view it connects to.
 * - meta.titleKey: The key used for the title that is shown in the browser tab.
 * - name: A unique name
 * - path: The path of the URL that is shown in the browser or that can be used
 * for direct links.
 *
 * The meta object has additional, optional settings:
 * - meta.parentRouteName: Required if the route is nested, has to be the name
 * of the outer route.
 * - meta.noDefaultContainer: Allows to opt-out of the default container that is
 * applied around the router-view. Useful if your page has some kind of special
 * layout.
 * - meta.requiresRole: If the user doesn't have at least one of the roles
 * listed here, the roleGuard forwards to a forbidden page.
 *   - meta.requiresRole.realmAdmin
 *   - meta.requiresRole.companyAdmin (note that if the route includes a company
 *   parameter, it must also match with the company id of the user)
 *   - meta.requiresRole.projectAdmin (note that if the route includes a company
 *   parameter, it must also match with the company id of the user, and that if
 *   the route includes a project parameter, it must match the project id of the
 *   role)
 * - meta.requiresProject: If present and set to true the projectGuard reroutes
 * to an error page if the project parameter is missing.
 * - meta.requiresComponentInProject: Same as requiresProject, but for the id of
 * a project component.
 */
const routes: RouteRecordRaw[] = [
  {
    component: AuthCallback,
    meta: {
      titleKey: 'links.meta.title.forwarding',
    },
    name: 'auth-callback',
    path: '/auth',
  },
  {
    component: AuthFailed,
    meta: {
      titleKey: 'links.meta.title.auth_failed',
    },
    name: 'auth-failed',
    path: '/auth-failed',
  },
  {
    component: AuthSilentRenew,
    meta: {
      titleKey: 'links.meta.title.forwarding',
    },
    name: 'auth-silent-renew',
    path: '/auth-silent-renew',
  },
  {
    component: UserProfile,
    meta: {
      titleKey: 'links.meta.title.user.profile',
    },
    name: 'user-profile',
    path: '/user/profile',
  },
  {
    // redirect to support password managers
    path: '/.well-known/change-password',
    redirect: {
      name: 'user-security',
    },
  },
  {
    component: UserSecurity,
    meta: {
      titleKey: 'links.meta.title.user.security',
    },
    name: 'user-security',
    path: '/user/security',
  },
  {
    component: Portfolio,
    meta: {
      titleKey: 'links.meta.title.home',
    },
    name: 'home',
    path: '/',

  },
  {
    component: Reporting,
    meta: {
      titleKey: 'links.meta.title.reporting',
    },
    name: 'reporting',
    path: '/reporting',
  },
  {
    component: OptimizationOptimizations,
    meta: {
      requiresProject: true,
      titleKey: 'links.meta.title.optimization',
    },
    name: 'optimization-optimizations',
    path: '/optimization/:project',
  },
  {
    component: OptimizationChecklist,
    meta: {
      noDefaultContainer: true,
      requiresProject: true,
      titleKey: 'links.meta.title.optimization_checklist',
    },
    name: 'optimization-checklist',
    path: '/optimization/checklist/:project',
  },
  {
    children: [
      {
        component: OptimizationSelectedComponentAnalyses,
        meta: {
          parentRouteName: 'optimization-components',
          requiresComponentInProject: true,
          requiresProject: true,
          titleKey: 'links.meta.title.optimization_components',
        },
        name: 'optimization-selected-component-analyses',
        path: ':componentInProjectId',
      },
    ],
    component: OptimizationComponents,
    meta: {
      requiresProject: true,
      titleKey: 'links.meta.title.optimization_components',
    },
    name: 'optimization-components',
    path: '/optimization/components/:project',
  },
  {
    path: '/optimization/potentials/:project',
    redirect: to => ({ path: `/optimization/recommendations/${to.params.project}` }),
  },
  {
    component: OptimizationRecommendations,
    meta: {
      requiresProject: true,
      titleKey: 'links.meta.title.optimization_recommendations',
    },
    name: 'optimization-recommendations',
    path: '/optimization/recommendations/:project',
  },
  {
    component: AssetOverview,
    meta: {
      requiresProject: true,
      titleKey: 'links.meta.title.asset_overview',
    },
    name: 'asset-overview',
    path: '/asset-overview/:project',
  },
  {
    component: AssetProfile,
    meta: {
      requiresProject: true,
      titleKey: 'links.meta.title.asset_profile',
    },
    name: 'asset-profile',
    path: '/asset-overview/:project/profile',
  },
  {
    component: AssetDocuments,
    meta: {
      requiresProject: true,
      titleKey: 'links.meta.title.asset_documents',
    },
    name: 'asset-documents',
    path: '/asset-overview/:project/documents',
  },
  /* {
    alias: '/savings/:project',
    component: Savings,
    meta: {
      requiresProject: true,
      titleKey: 'links.meta.title.savings',
    },
    name: 'savings',
    path: '/savings/:project',
  }, */
  {
    component: AIControls,
    meta: {
      requiresProject: true,
      titleKey: 'links.meta.title.ai_controls',
    },
    name: 'ai-controls',
    path: '/ai-controls/:project',
  },
  {
    component: AIControlsApp,
    meta: {
      requiresProject: true,
      titleKey: 'links.meta.title.ai_controls_app',
    },
    name: 'ai-controls-app',
    path: '/ai-controls/app/:project',
  },
  {
    component: AIControlsApp,
    meta: {
      requiresProject: true,
      titleKey: 'links.meta.title.ai_controls_app',
    },
    name: 'ai-controls-app-id',
    path: '/ai-controls/app/:project/:appId',
  },
  {
    component: AIControlsLog,
    meta: {
      requiresProject: true,
      titleKey: 'links.meta.title.ai_controls_log',
    },
    name: 'ai-controls-log',
    path: '/ai-controls/log/:project',
  },
  {
    component: DataPointsView,
    meta: {
      requiresProject: true,
      titleKey: 'links.meta.title.data_points_view',
    },
    name: 'data-points',
    path: '/data-points/:project',
  },
  {
    component: Status,
    meta: {
      titleKey: 'links.meta.title.status',
      noDefaultContainer: true,
    },
    name: 'status',
    path: '/status',
  },
  {
    component: StatusAndAlerts,
    meta: {
      requiresProject: true,
      titleKey: 'links.meta.title.status_and_alerts',
    },
    name: 'status-and-alerts',
    path: '/status-and-alerts/:project',
  },
  {
    component: Administration,
    meta: {
      requiresRole: {
        companyAdmin: true,
      },
      titleKey: 'links.meta.title.administration',
    },
    name: 'administration',
    path: '/administration',
  },
  {
    component: AdministrationUsers,
    meta: {
      requiresRole: {
        companyAdmin: true,
      },
      titleKey: 'links.meta.title.manage_users',
    },
    name: 'administration-users',
    path: '/administration/users',
  },
  {
    component: AdministrationUserDetails,
    meta: {
      requiresRole: {
        companyAdmin: true,
      },
    },
    name: 'administration-user-details',
    path: '/administration/users/:user',
  },
  {
    component: Forbidden,
    meta: {
      titleKey: 'links.meta.title.forbidden',
    },
    name: 'forbidden',
    path: '/forbidden',
  },
  {
    component: PageNotFound,
    meta: {
      titleKey: 'links.meta.title.page_not_found',
    },
    name: 'page-not-found',
    path: '/:pathMatch(.*)*',
  },
  {
    component: DataPrivacy,
    meta: {
      titleKey: 'links.meta.title.data_privacy',
    },
    name: 'data-privacy',
    path: '/privacy',
  },
  {
    component: CookieList,
    meta: {
      titleKey: 'links.meta.title.cookie_list',
    },
    name: 'cookie-list',
    path: '/cookies',
  },
  {
    component: EnergyAndCO2,
    meta: {
      titleKey: 'links.meta.title.energy_and_co2',
    },
    name: 'energy-and-co2',
    path: '/energy-and-co2',
  },
  {
    component: Meter,
    meta: {
      requiresProject: true,
      titleKey: 'links.meta.title.meter',
    },
    name: 'meter',
    path: '/meter/:project',
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior (to, from) {
    if (to.hash) {
      return {
        behavior: 'smooth',
        selector: to.hash,
      }
    }
    // If only query is changed, don't scroll
    if (to.path === from.path && JSON.stringify(to.query) !== JSON.stringify(from.query)) {
      return false
    }
    return { left: 0, top: 0 }
  },
} as RouterOptions)

onInitialApiRequestsCompleted(() => {
  const links: ProjectLinkData[] = store.getters['projects/linksForAllProjects']
  for (const link of links) {
    const route = {
      component: IFrame,
      meta: {
        project: link.project,
        projectSpecific: true,
        requiresProject: true,
        title: link.title,
      },
      name: link.routeName,
      path: link.path,
      props: {
        target: link.target,
        title: link.title,
      },
    }
    router.addRoute(route)
  }
}, Priority.High)

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'auth'))

router.beforeEach(roleGuardMiddleware())

router.beforeEach(titleGuardMiddleware(texts.appName))

router.beforeEach(projectGuardMiddleware(store))

router.beforeEach(homepageGuardMiddleware(store))

router.beforeEach(componentInProjectGuardMiddleware)

router.beforeEach(queryGuardMiddleware(store))

router.afterEach(() => {
  window.productFruits?.pageChanged?.()
})

router.afterEach((to, from, failure) => {
  if (!failure) {
    setTimeout(() => {
      window?.HSStaticMethods?.autoInit()
    }, 100)
  }
})

export default router
