<i18n lang="json" locale="de">
{
  "portfolio": "Portfolio"
}
</i18n>
<i18n lang="json" locale="en">
{
  "portfolio": "Portfolio"
}
</i18n>

<template>
  <v-navigation-drawer
    class="pa-2"
    style="background-color: rgb(var(--v-theme-neutral-darken4));"
    data-cy="navigation"
    permanent
    width="200"
  >
    <v-overlay
      contained
      absolute
      class="overlay"
      scrim="neutral-darken4"
      opacity="0.8"
      :model-value="assetSelectorOpen || userMenuOpen"
    />

    <NavigationLogo v-if="!isStrabagDemoUser" />

    <v-list
      density="compact"
    >
      <NavigationHeader :text="t('portfolio')" />

      <NavigationItemGroup
        v-if="hasMultipleAssets"
        class="mb-5"
        :items="portfolioNavigationItems"
      />

      <NavigationAssetSelector
        :items="assetSelectorItems"
        :value="currentProjectId ?? undefined"
        @asset-selector:change="onProjectChanged"
        @asset-selector:toggled="assetSelectorOpen = $event"
      />

      <NavigationItemGroup
        :items="projectNavigationItems"
      />
    </v-list>

    <template #append>
      <NavigationUserMenu
        :loading="loadingUser"
        :user="user"
        @user-menu:sign-out="signOutOidc"
        @user-menu:toggled="userMenuOpen = $event"
      />
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { AssetItemData, NavigationItemData } from './types'
import { mapActions, mapGetters } from 'vuex'
import { ProjectLinkData, ProjectPayload } from '@/vuex/projects/types'
import { defineComponent } from 'vue'
import { isAssetOverviewSetUp } from '@/utils/helpers/viewStatus'
import { mapState } from 'pinia'
import NavigationAssetSelector from './NavigationAssetSelector.vue'
import NavigationHeader from './NavigationHeader.vue'
import NavigationItemGroup from './NavigationItemGroup.vue'
import NavigationLogo from './NavigationLogo.vue'
import NavigationUserMenu from './NavigationUserMenu.vue'
import { onInitialApiRequestsCompleted } from '@/utils/helpers/hooks'
import { Project } from '@aedifion.io/aedifion-api'
import { useAIControlsAppStore } from '@/stores/views/AIControls/App/app'
import { useAppStore } from '@/stores/app'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/user'
import useIsStrabagDemoUser from '@/composables/useIsStrabagDemoUser'

export default defineComponent({
  name: 'Navigation',

  components: {
    NavigationAssetSelector,
    NavigationHeader,
    NavigationItemGroup,
    NavigationLogo,
    NavigationUserMenu,
  },

  setup () {
    const { t } = useI18n()
    return {
      appStore: useAppStore(),
      t,
    }
  },

  data () {
    return {
      assetSelectorOpen: false as boolean,
      projectLinksLoaded: false as boolean,
      userMenuOpen: false as boolean,
    }
  },

  computed: {
    ...mapGetters({
      linksForCurrentProject: 'projects/linksForCurrentProject',
    }),

    ...mapState(useUserStore, {
      isCompanyAdmin: 'isCompanyAdmin',
      loadingUser: 'loading',
      user: 'condensedUserInformation',
    }),

    ...mapState(useAIControlsAppStore, {
      controlsApp: 'controlsApp',
    }),

    aiControlsNavigationChildren (): NavigationItemData[] {
      if (this.currentProjectId === null || !this.appStore.currentProjectHasControlsApp) return []
      const project = this.currentProjectId.toString()
      const appId = this.controlsApp?.id
      return [
        {
          routeNames: ['ai-controls-app', 'ai-controls-app-id'], // show on both routes
          title: this.t('links.meta.title.ai_controls_app'),
          to: { name: appId ? 'ai-controls-app-id' : 'ai-controls-app', params: { appId, project } }, // route to app id if already available
        },
        {
          routeNames: ['ai-controls-log'],
          title: this.t('links.meta.title.ai_controls_log'),
          to: { name: 'ai-controls-log', params: { project } },
        },
      ]
    },

    assetSelectorItems (): Array<AssetItemData> {
      const projects: Array<Project> = this.$store.getters['projects/getCondensedProjects']
      return projects.map((project: Project) => {
        return {
          title: project.name,
          value: project.id!,
        } as AssetItemData
      })
    },

    currentProjectId (): number|null {
      this.$store.dispatch('projects/ensureProjectSelected')
      const projectId = this.$store.getters['projects/currentProjectId']
      this.appStore.setProjectId(projectId)
      return projectId
    },

    hasMultipleAssets (): boolean {
      return this.assetSelectorItems.length > 1
    },

    portfolioNavigationItems (): NavigationItemData[] {
      return [{
        icon: 'fa:far fa-buildings',
        routeNames: ['home'],
        title: this.t('links.meta.title.home'),
        to: { name: 'home' },
      },
      {
        icon: 'fa:far fa-sparkle',
        routeNames: ['status'],
        title: this.t('links.meta.title.status'),
        to: { name: 'status' },
      },
      {
        icon: 'fa:far fa-file-chart-column',
        routeNames: ['reporting'],
        title: this.t('links.meta.title.reporting'),
        to: { name: 'reporting' },
      },
      {
        icon: 'fa:far fa-chart-scatter',
        routeNames: ['energy-and-co2'],
        title: this.t('links.meta.title.energy_and_co2'),
        to: { name: 'energy-and-co2' },
      }]
    },

    projectNavigationItems (): NavigationItemData[] {
      if (this.currentProjectId === null) {
        return []
      }
      const project = this.currentProjectId.toString()
      const result = [{
        children: this.isStrabagDemoUser
          ? undefined
          : [{
            routeNames: ['asset-profile'],
            title: this.t('links.meta.title.asset_profile'),
            to: { name: 'asset-profile', params: { project } },
          },
          {
            routeNames: ['asset-documents'],
            title: this.t('links.meta.title.asset_documents'),
            to: { name: 'asset-documents', params: { project } },
          }],
        icon: 'fa:far fa-building',
        routeNames: ['asset-overview'],
        title: this.t('links.meta.title.asset_overview'),
        to: { name: 'asset-overview', params: { project } },
      },
      /* {
        icon: 'fa:far fa-piggy-bank',
        routeNames: ['savings'],
        title: this.t('links.meta.title.savings'),
        to: { name: 'savings', params: { project } },
      }, */
      {
        children: this.isStrabagDemoUser
          ? undefined
          : [{
            routeNames: ['optimization-checklist'],
            title: this.t('links.meta.title.optimization_checklist') as string,
            to: { name: 'optimization-checklist', params: { project } },
          },
          {
            routeNames: ['optimization-selected-component-analyses', 'optimization-components'],
            title: this.t('links.meta.title.optimization_components'),
            to: { name: 'optimization-components', params: { project } },
          },
          {
            routeNames: ['optimization-recommendations'],
            title: this.t('links.meta.title.optimization_recommendations'),
            to: { name: 'optimization-recommendations', params: { project } },
          }],
        icon: 'fa:far fa-rocket',
        routeNames: this.isStrabagDemoUser ? ['optimization-recommendations'] : ['optimization-optimizations'],
        title: this.t('links.meta.title.optimization'),
        to: { name: this.isStrabagDemoUser ? 'optimization-recommendations' : 'optimization-optimizations', params: { project } },
      },
      {
        children: this.isStrabagDemoUser ? undefined : this.aiControlsNavigationChildren,
        icon: 'fa:far fa-wand-magic-sparkles',
        routeNames: ['ai-controls'],
        title: this.t('links.meta.title.ai_controls'),
        to: { name: 'ai-controls', params: { project } },
      }]

      return this.isStrabagDemoUser ? result : this.withAdditionalMenuItems(result)
    },

    isStrabagDemoUser (): boolean {
      return useIsStrabagDemoUser()
    },
  },

  async created () {
    onInitialApiRequestsCompleted(() => {
      this.projectLinksLoaded = true
    })
  },

  methods: {
    ...mapActions('auth', [
      'signOutOidc',
    ]),

    insertNavigationItem (allItems: NavigationItemData[], newItem: NavigationItemData, before?: string): void {
      if (!before) {
        allItems.push(newItem)
        return
      }

      const targetIndex = allItems.findIndex((item) =>
        item.routeNames.includes(before),
      )

      if (targetIndex !== -1) {
        allItems.splice(targetIndex, 0, newItem)
      } else {
        allItems.push(newItem)
      }
    },

    onProjectChanged (assetItem: AssetItemData) {
      if (assetItem.value !== undefined && assetItem.value !== this.$store.getters['projects/currentProjectId']) {
        if (this.$route.name === 'home' || this.$route.meta?.projectSpecific) {
          const routeName = isAssetOverviewSetUp(assetItem.value) ? 'asset-overview' : 'optimization-optimizations'
          this.$router.push({ name: routeName, params: { project: assetItem.value.toString() } })
        } else if (this.$route.meta?.requiresProject) {
          const routeName = this.$route.meta?.parentRouteName || this.$route.name!
          this.$router.push({ name: routeName as string, params: { project: assetItem.value.toString() } }).catch(error => error)
        } else {
          const projectId = Number(assetItem.value)
          const payload: ProjectPayload = {
            projectId,
            showNotification: false,
          }
          this.$store.dispatch('projects/selectProject', payload)
          this.appStore.setProjectId(projectId)
        }
      }
    },

    withAdditionalMenuItems (items: NavigationItemData[]): NavigationItemData[] {
      const project = this.currentProjectId?.toString()
      items.push({
        icon: 'fa:far fa-bell',
        routeNames: ['status-and-alerts'],
        title: this.t('links.meta.title.status_and_alerts'),
        to: { name: 'status-and-alerts', params: { project } },
      },
      {
        icon: 'fa:far fa-meter',
        routeNames: ['meter'],
        title: this.t('links.meta.title.meter'),
        to: { name: 'meter', params: { project } },
      },
      {
        divider: true,
        icon: 'fa:far fa-project-diagram',
        routeNames: ['data-points'],
        title: this.t('links.meta.title.data_points_view'),
        to: { name: 'data-points', params: { project } },
      })
      if (this.isCompanyAdmin()) {
        items.push({
          children: [{
            routeNames: ['administration-users', 'administration-user-details'],
            title: this.t('links.meta.title.manage_users'),
            to: { name: 'administration-users' },
          }],
          divider: true,
          icon: 'fa:far fa-users-rectangle',
          routeNames: ['administration'],
          title: this.t('links.meta.title.administration'),
          to: { name: 'administration' },
        })
      }
      if (this.currentProjectId === null || !this.projectLinksLoaded) {
        return items
      }
      for (const link of (this.linksForCurrentProject as ProjectLinkData[])) {
        const additionalNavItem: NavigationItemData = {
          icon: link.source.icon,
          routeNames: [link.routeName],
          title: link.title,
          to: { path: link.path },
        }

        if (link.source.parent) {
          const parent = items.find((item: NavigationItemData) => {
            return item.routeNames.includes(link.source.parent!)
          })
          if (parent) {
            if (parent.children) {
              this.insertNavigationItem(parent.children, additionalNavItem, link.source.before)
            } else {
              parent.children = [additionalNavItem]
            }
          }
        } else {
          this.insertNavigationItem(items, additionalNavItem, link.source.before)
        }
      }
      return items
    },
  },
})
</script>

<style lang="sass" scoped>
.overlay
  top: 40px
  right: 1px
</style>
