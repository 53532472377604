import { aedifionApi, ComponentInProjectWithContext, DataPointWithContext, Pin, Success } from '@aedifion.io/aedifion-api'
import { MappingEditorState, UpdateMappingPayload } from './types'
import { showErrorNotification, showWarningNotification } from '@/utils/helpers/notifications'
import { ActionTree } from 'vuex'
import i18n from '@/i18n'
import { reportError } from '@/utils/helpers/errors'
import { resetStoreState } from './state'
import { RootState } from '../types'

export default {
  clear: ({ state }) => {
    resetStoreState(state)
  },

  fetchData: async ({ commit, rootGetters }, componentInProjectId: number) => {
    commit('SET_LOADING', true)
    try {
      const projectId = rootGetters['projects/currentProjectId'] as number|null

      const projectComponentApiResponse: ComponentInProjectWithContext = await aedifionApi.Project.getProjectComponent(projectId!, componentInProjectId)

      const pinsApiResponse: Pin[] = await aedifionApi.Component.getComponentPins(projectComponentApiResponse.component!.id!)

      const mappedDatapointsWithContext: DataPointWithContext[] = []
      for (const assignedPin of projectComponentApiResponse.pins || []) {
        const datapoint: DataPointWithContext = await aedifionApi.Datapoint.getDatapoint(assignedPin.dataPointID!, projectId!)
        mappedDatapointsWithContext.push(datapoint)
      }

      commit('SET_COMPONENT_IN_PROJECT_WITH_CONTEXT', projectComponentApiResponse)
      commit('SET_PINS', pinsApiResponse)
      commit('SET_MAPPED_DATAPOINTS', mappedDatapointsWithContext)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.component_data') })}`)
      reportError(error)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  mapDatapointToPin: async ({ commit, getters, rootGetters }, payload: UpdateMappingPayload) => {
    commit('ADD_PENDING_MAPPING_UPDATE', payload.pinId)
    try {
      const projectId = rootGetters['projects/currentProjectId'] as number|null

      if (payload.datapointId) {
        let apiResponse: Success
        if (getters.isPinMapped(payload.pinId)) {
          apiResponse = await aedifionApi.Project.putProjectComponentPinDatapoint(projectId!, payload.componentInProjectId, payload.pinId, payload.datapointId)
        } else {
          apiResponse = await aedifionApi.Project.postProjectComponentPinDatapoint(projectId!, payload.componentInProjectId, payload.pinId, payload.datapointId)
        }

        const datapoint: DataPointWithContext = await aedifionApi.Datapoint.getDatapoint(payload.datapointId, projectId!)

        commit('SET_COMPONENT_IN_PROJECT_WITH_CONTEXT', apiResponse.resource)
        commit('ADD_MAPPED_DATAPOINT', datapoint)
      }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.status === 400) {
        showWarningNotification(i18n.global.t('notifications.errors.mapping.mapped') as string)
      } else {
        showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.component_data') })}`)
        reportError(error)
      }
    } finally {
      commit('REMOVE_PENDING_MAPPING_UPDATE', payload.pinId)
    }
  },

  unmapDatapointFromPin: async ({ commit, rootGetters }, payload: UpdateMappingPayload) => {
    commit('ADD_PENDING_MAPPING_UPDATE', payload.pinId)
    try {
      const projectId = rootGetters['projects/currentProjectId'] as number|null

      await aedifionApi.Project.deleteProjectComponentPinDatapoint(projectId!, payload.componentInProjectId, payload.pinId)
      commit('REMOVE_PIN_FROM_COMPONENT_IN_PROJECT', payload.pinId)

      commit('REMOVE_MAPPED_DATAPOINT', payload.datapointId)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.component_data') })}`)
      reportError(error)
    } finally {
      commit('REMOVE_PENDING_MAPPING_UPDATE', payload.pinId)
    }
  },
} as ActionTree<MappingEditorState, RootState>
