import { AssignedPin, ComponentInProjectWithContext, DataPointWithContext, Pin } from '@aedifion.io/aedifion-api'
import { MappingEditorState } from './types'
import { MutationTree } from 'vuex'

export default {
  ADD_MAPPED_DATAPOINT: (state: MappingEditorState, mappedDatapoint: DataPointWithContext) => {
    if (!state.mappedDatapointsWithContext) {
      state.mappedDatapointsWithContext = []
    }
    state.mappedDatapointsWithContext.push(mappedDatapoint)
  },

  ADD_PENDING_MAPPING_UPDATE: (state: MappingEditorState, pinID: number) => {
    state.pinsWithPendingMappingUpdates.push(pinID)
  },

  REMOVE_MAPPED_DATAPOINT: (state: MappingEditorState, dataPointID: string | null) => {
    if (state.mappedDatapointsWithContext) {
      const index: number = state.mappedDatapointsWithContext.findIndex((item: DataPointWithContext) => item.dataPointID === dataPointID)
      if (index >= 0) {
        state.mappedDatapointsWithContext.splice(index, 1)
      }
    }
  },

  REMOVE_PENDING_MAPPING_UPDATE: (state: MappingEditorState, pinID: number) => {
    state.pinsWithPendingMappingUpdates.splice(state.pinsWithPendingMappingUpdates.indexOf(pinID))
  },

  REMOVE_PIN_FROM_COMPONENT_IN_PROJECT: (state: MappingEditorState, pinID: number) => {
    if (state.componentInProjectWithContext) {
      const index: number = state.componentInProjectWithContext.pins!.findIndex((item: AssignedPin) => item.id === pinID)
      if (index >= 0) {
        state.componentInProjectWithContext.pins!.splice(index, 1)
      }
    }
  },

  SET_COMPONENT_IN_PROJECT_WITH_CONTEXT: (state: MappingEditorState, componentInProject: ComponentInProjectWithContext) => {
    state.componentInProjectWithContext = componentInProject
  },

  SET_LOADING: (state: MappingEditorState, loading: boolean) => (state.loading = loading),

  SET_MAPPED_DATAPOINTS: (state: MappingEditorState, mappedDatapoints: DataPointWithContext[]) => (state.mappedDatapointsWithContext = mappedDatapoints),

  SET_PINS: (state: MappingEditorState, pins: Pin[]) => (state.pins = pins),
} as MutationTree<MappingEditorState>
